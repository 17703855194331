import PropTypes from 'prop-types';

import Counter from '../UI/Counter/Counter';
import styles from './GiftCardThumb.module.css';
import Prices from './Prices/Prices';
import PreloadImage from '../UI/PreloadImage/PreloadImage';
import Button from '../UI/Button/Button';
import { Link } from 'react-router-dom';
import { CART } from '../../constants/routes';

const GiftCardThumb = props => {
  const {
    item: {
      productImagePath,
      productName,
      productOrderType,
      price,
      purchaseProductId,
      taxrate,
      mandatory,
      amount,
      description,
      voucherCode,
      sortPosition,
    },
  } = props;

  const {
    selAmount,
    onAddToCart,
    decCounterClicked,
    incCounterClicked,
    onCounterChange,
  } = props;

  console.log('selAmount= ' + props.selAmount);

  return (
    <div className={styles.Product}>
      <div className={styles.ProductInner}>
        <div className={styles.ImageBox}>
          <PreloadImage
            src={productImagePath}
            alt={productName}
            className={styles.Image}
            loadingClass={styles.LoadingImage}
          />
        </div>
        <div className={styles.Details}>
          <h3 className={styles.Title}>{productName}</h3>
          {/*<Prices price={price} /> */}
        </div>
        <div className={styles.CounterButtonWrapper}>
          <div className={styles.CounterWrapper}>
            <Counter
              disabled={mandatory}
              value={selAmount}
              incClicked={e => incCounterClicked(e, purchaseProductId)}
              decClicked={e => decCounterClicked(e, purchaseProductId)}
              onChange={e => onCounterChange(e, purchaseProductId)}
            />
          </div>
          <div className={styles.ButtonWrapper}>
            <Button
              theme="big"
              clicked={e => onAddToCart(e)}
              className={styles.CTAButton}
            >
              <Link
                to={`${CART}`}
                title="In den Warenkorb"
                className={styles.CTAButton}
              >
                In den Warenkorb
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

GiftCardThumb.propTypes = {
  onAddToCart: PropTypes.func,
  item: PropTypes.object,
  selAmount: PropTypes.number,
  incCounterClicked: PropTypes.func,
  decCounterClicked: PropTypes.func,
  onCounterChange: PropTypes.func,
};

export default GiftCardThumb;
