import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import * as APP_CONST from '../../constants/appConsts';
import GiftCardList from '../../containers/GiftCardList/GiftCardList';
import * as actions from '../../store/actions/index';
import ResponsiveWrapper from '../../components/UI/ResponsiveWrapper/ResponsiveWrapper';
import GiftCardContentHeader from '../../components/GiftcardContentHeader/GiftCardContentHeader';
import { Helmet } from 'react-helmet';
import { GIFTCARD as landingTitle } from '../../constants/titles';
import { Carousel, Image, Col, Row } from 'react-bootstrap';
import Flohmarktstyles from '../../components/FlohmarktContentHeader/FlohmarktContentHeader.module.css';
import image1 from '../../assets/images/autokino/kinogutschein/gutschein_kino_glueckliche_besucher.jpg';
import Kinostyles from '../../components/Util/AutokinoStylesComponent.module.css';
import * as ROUTES from '../../constants/routes';

const AnimatedContainer = posed.section({
  enter: {
    opacity: 1,
    staggerChildren: 200,
  },
  exit: {
    opacity: 0,
  },
});

const GiftCardPage = props => {
  const [selGiftCardsAmount, setSelGiftCardsAmount] = useState({});

  useEffect(() => {
    async function fetchGiftCards() {
      console.log('call fetch giftCards');
      await props.fetchGiftCards();
    }

    fetchGiftCards();
  }, []);

  let counterHandler = (e, id, type) => {
    const data = { ...selGiftCardsAmount };
    const oldCounter = data[id] != null ? data[id] : 1;
    let updatedCounter = oldCounter;

    console.log(
      'oldCounter Val = ' + oldCounter + ',id = ' + id + ', type = ' + type
    );

    if (type === 'inc') {
      updatedCounter += 1;
    }

    if (type === 'dec') {
      if (updatedCounter > 0) {
        updatedCounter -= 1;
      }
    }

    if (type === 'change') {
      const val = +e.target.value;
      if (val >= 0) {
        updatedCounter = Math.abs(val);
      }
      if (val > 600) {
        updatedCounter = 600;
      }
    }

    console.log('changed value = ' + updatedCounter);
    setSelGiftCardsAmount(prevState => ({
      ...prevState, // copy all other field/objects
      [id]: updatedCounter,
    }));
  };

  const renderPageHeader = () => {
    let retcontent = <div />;
    console.log('render page header called');
    retcontent = (
      <div>
        <GiftCardContentHeader />
        <Helmet defer={false}>
          <title>Kinogutschein Autokino &#x1F381; Jetzt Freude schenken!</title>
          <meta
            name="description"
            content="&#x1F381; Der (Auto) Kino Gutschein. Immer ein großartiges Geschenk! Freude vorprogrammiert! Einzigartiges Kinoerlebnis, Hollywood unter Sternen mit Top Service. Jetzt Kinogutschein online kaufen."
          />
          <meta
            name="keywords"
            content="Kinogutschein – schenke #ErlebnisAutokino"
          />
          <title>{landingTitle}</title>
          <link rel="canonical" href="https://autokino.at/kinogutschein" />
        </Helmet>
      </div>
    );
    return retcontent;
  };

  const renderGiftcardInfo = () => {
    let retcontent = <div />;
    console.log('render gift card info called cat = ');
    retcontent = (
      <ResponsiveWrapper>
        <Row className={Flohmarktstyles.Flex}>
          <Col xs={12} lg={12}>
            <h2 className={Flohmarktstyles.HardelaHeadline}>
              Infos zu unseren Kinogutscheinen
            </h2>
            <h3 className={Flohmarktstyles.HardelaHeadlineSmall + ' mt-2'}>
              Wie kommt der Gutschein zu Dir?
            </h3>
            <div className={Flohmarktstyles.TextContainer + ' mt-2'}>
              {' '}
              Der Kinogutschein wird Dir per E-Mail als hübsch designte
              "PDF-Datei", sofort nach deiner Bestellung, automatisch
              zugesendet. <br />
              Achte darauf, dass Du deine Mailadresse richtig angibst. Findest
              Du deinen Kino Gutschein nach 5 Minuten nicht in deinem Postfach,
              wirf doch bitte einen Blick in deinen Spam-Ordner.
            </div>
            <h3 className={Flohmarktstyles.HardelaHeadlineSmall + ' mt-2'}>
              Wie kann man den Kinogutschein einlösen?
            </h3>
            <div className={Flohmarktstyles.TextContainer + ' mt-2'}>
              Einlösbar ist der Gutschein <u>ausschließlich online</u> auf
              unserer Website www.autokino.at für Kino-Tickets und unser Welcome
              Package (Getränke + Popcorn + Goodies). <br />
              <br />
              Solltest Du nicht den ganzen Gutscheinbetrag verwendet haben,
              kannst Du bei deiner nächsten Bestellung den übrig gebliebenen
              Betrag einlösen. Gib einfach denselben Gutscheincode noch einmal
              ein. <br />
              <br />
              Sollte deine Bestellsumme höher sein, als dein Kinogutschein,
              kannst Du den Restbetrag mit allen gängigen Zahlungsmethoden
              begleichen.
              <br />
              <br />
              Die Gültigkeitsdauer ist am Gutschein vermerkt (aktuell bis Ende
              2025), diese kann aber gerne jederzeit auf Anfrage verlängert
              werden.
              <br />
              <br />
            </div>
            <h3 className={Flohmarktstyles.HardelaHeadlineSmall + ' mt-2'}>
              Kinogutschein kaufen. Freude schenken. Der Autokino Gutschein.
            </h3>
            <div className={Flohmarktstyles.TextContainer + ' mt-4'}>
              <b>#DasBesondereKinoErlebnis</b>
              <br />
              <br />
              <Carousel xs={12} lg={12} className={'p-2'}>
                <Carousel.Item>
                  <Image
                    src={image1}
                    fluid
                    alt="hund im auto kino wien"
                    title="hund kino autokino wien"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
            <br />
            <div className={Kinostyles.TextContainer + ' mt-2'}>
              <br />
              <button className={Kinostyles.CTAButton}>
                <a
                  href={ROUTES.LANDING_HEADER_SECTION}
                  className={Kinostyles.CTAButtonLink}
                >
                  Kinoprogramm
                </a>
              </button>
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </ResponsiveWrapper>
    );
    return retcontent;
  };

  const addItemToCart = (e, item) => {
    console.log('added to cart PAGE productID = ' + item.productName);
    // TODO item add to cart
    // const item = products2Cart;

    const data = { ...selGiftCardsAmount };
    const amount =
      data[item.purchaseProductId] != null ? data[item.purchaseProductId] : 1;

    console.log('add to cart amount = ' + amount);

    const itemProduct = {
      key: item.purchaseProductId,
      product: item,
      order: item.purchaseProductId,
      amount: amount,
      id: item.purchaseProductId,
      totalPrice: amount * item.price,
      show: {},
      movie: {},
      productType: APP_CONST.PRODUCT_TYPE_VOUCHER,
    };

    let productArray = [];
    productArray.push(itemProduct);
    props.onAddToCart(productArray);
  };

  console.log('render giftCards = ' + props.giftCards.length);
  return (
    <div>
      {renderPageHeader()}
      <ResponsiveWrapper>
        <Row className={Flohmarktstyles.Flex}>
          <Col xs={12} lg={12}>
            <h1 className={Flohmarktstyles.HardelaHeadline}>
              Jetzt Kinogutschein schenken - #DasBesondereKinoErlebnis
            </h1>
            <div className={Flohmarktstyles.TextContainer + ' mt-4'}>
              Einzigartiges Kinoerlebnis kann man jetzt auch verschenken!
              <br />
              Schenke die einzigartige Stimmung wunderbarer Sonnenuntergänge mit
              nachfolgendem Hollywood Blockbustern. Freiheit pur!
              #HollywoodUnterSternen
              <br />
              <br />
              Schöne Zeit schenken war selten so einfach und unkompliziert!
              <br />
              <br />
            </div>
          </Col>
        </Row>
      </ResponsiveWrapper>
      <ResponsiveWrapper>
        <h2 className={Flohmarktstyles.HardelaHeadline}>
          Kinogutschein kaufen
        </h2>
        <AnimatedContainer />
        <GiftCardList
          amounts={selGiftCardsAmount}
          giftCards={props.giftCards}
          onAddToCart={(e, item) => addItemToCart(e, item)}
          incCounterClicked={(e, id) => counterHandler(e, id, 'inc')}
          decCounterClicked={(e, id) => counterHandler(e, id, 'dec')}
          onCounterChange={(e, id) => counterHandler(e, id, 'change')}
        />
      </ResponsiveWrapper>
      {renderGiftcardInfo()}
    </div>
  );
};

GiftCardPage.propTypes = {
  giftCards: PropTypes.arrayOf(PropTypes.object),
  fetchGiftCards: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    giftCards: state.giftCard.giftCards,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchGiftCards: () => dispatch(actions.fetchGiftCard()),
    onAddToCart: item => dispatch(actions.addToCart(item)),
  };
};

// nFetchCategories
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardPage);
