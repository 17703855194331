import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ShowList from '../../containers/ShowsList/ShowsList';
import { LANDING as landingTitle } from '../../constants/titles';
// import * as actions from '../../store/actions/index';
import { fetchShows } from '../../store/actions';
import { saveMovieReminderSubscription } from '../../store/actions';
import ResponsiveWrapper from '../../components/UI/ResponsiveWrapper/ResponsiveWrapper';
import { Carousel, CarouselItem, Col, Image, Row } from 'react-bootstrap';
import Kinostyles from '../../components/Util/AutokinoStylesComponent.module.css';
import * as ROUTES from '../../constants/routes';
import image1 from '../../assets/images/autokino/kinoprogramm/Lounge.jpg';
import iconSieger from '../../assets/images/autokino/kinoprogramm/_Sieger.png';
import iconPrivat from '../../assets/images/autokino/kinoprogramm/_Privacy.png';
import iconVierbeiner from '../../assets/images/autokino/kinoprogramm/_Vierbeiner.png';
import iconSnacks from '../../assets/images/autokino/kinoprogramm/_Liefern.png';
import imageZeitleiste from '../../assets/images/autokino/kinoprogramm/Schritte_Zeitleiste.svg';
import imageSterne from '../../assets/images/autokino/kinoprogramm/Sterne.svg';
import imageRezBackground from '../../assets/images/autokino/kinoprogramm/Rezensionen_Rahmen.svg';
import image3 from '../../assets/images/autokino/kinoprogramm/test_cockails_zum_kinoprogramm_wien_im_autokino.jpg';
import image4 from '../../assets/images/autokino/kinoprogramm/test_kino_parkplatz_besucher_kinofilm_start.jpg';
import image2 from '../../assets/images/autokino/kinoprogramm/test_kinosnacks_fuer kinoprogramm_geliefert_zum_auto.jpg';
import FilmprogrammSignup from '../../components/FilmprogrammSignup/FilmprogrammSignup';
import NewsletterSignup from '../../components/NewsletterSignup/NewsletterSignup';
import ScriptTag from 'react-script-tag/lib/ScriptTag';
import { animateScroll as scroll, scroller } from 'react-scroll';
import imageZeitleiste1 from '../../assets/images/autokino/kinoprogramm/desktop/1.svg';
import imageZeitleiste2 from '../../assets/images/autokino/kinoprogramm/desktop/2.svg';
import imageZeitleiste3 from '../../assets/images/autokino/kinoprogramm/desktop/3.svg';
import imageZeitleiste4 from '../../assets/images/autokino/kinoprogramm/desktop/4.svg';
import imagePrev from '../../assets/images/autokino/Pfeil_links.svg';
import imageNext from '../../assets/images/autokino/Pfeil_rechts.svg';
import imageTipp from '../../assets/images/autokino/kinoprogramm/desktop/Tipp_Rechteck.png';
import image1Desktop from '../../assets/images/autokino/showpage/desktop/Lounge.jpg';
import image2Desktop from '../../assets/images/autokino/showpage/desktop/Autokino_Golden_Hour.jpg';
import image3Desktop from '../../assets/images/autokino/showpage/desktop/Open_Air.jpg';

import { useRef } from 'react';
const LandingPage = props => {
  useEffect(() => {
    async function fetchShows() {
      console.log('call fetch categorties');
      await props.fetchShows(null);
    }

    fetchShows();

    const query = new URLSearchParams(window.location.search);
    const opensubscriptionpopup = query.get('openfpsub');
    const selectshow = query.get('selectshow');
    if (opensubscriptionpopup === 'true') {
      setTimeout(function() {
        if (typeof window.mjOpenPopin === 'function') {
          document.getElementById('filmprogrammNewsletterButton').click();
        } else {
          setTimeout(function() {
            document.getElementById('filmprogrammNewsletterButton').click();
          }, 4000);
        }
      }, 2000);
    }
    if (selectshow !== null && selectshow !== '') {
      setTimeout(function() {
        scrollTo(selectshow);
      }, 2000);
    }
  }, []);
  function scrollTo(anchor) {
    console.log('scrollTo called: ' + anchor);
    scroller.scrollTo(anchor, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }
  //console.log('render cat = ' + props.shows.length);

  const getPrefIcon = () => {
    return <Image src={imagePrev} style={{ width: '30px' }} />;
  };
  const getNextIcon = () => {
    return <Image src={imageNext} style={{ width: '30px' }} />;
  };

  return (
    <div>
      <Helmet defer={false}>
        <title>{landingTitle}</title>
        <meta
          name="description"
          content="Einzigartiges Kinoerlebnis. Sieger Preis / Leistung Kinotest 2022. Hier geht´s zum Kinoprogramm im Autokino Wien."
        />
        <meta
          name="keywords"
          content="Kinoprogramm Wien, Kino Wien, Kino, Autokino, Bestes Kino, Flohmarkt, Flohmarkt Wien, Eventlocation, Eventlocation Wien, Kinoticket kaufen, Kino Wien Programm, Jetzt im Kino Wien, Kino Samstag, Samstag im Kino, Kinofilme Samstag, Kino in der Nähe, Heute im Kino"
        />

        <link rel="canonical" href="https://autokino.at/kinoprogramm-wien/" />
      </Helmet>
      <div>
        <ResponsiveWrapper>
          <Row className={Kinostyles.Flex + ' d-lg-none'}>
            <Col xs={12} lg={0}>
              <h1 className={Kinostyles.BanthinkHeadlineYellow}>
                Kinoprogramm
              </h1>
              <div className={Kinostyles.TextContainer + ' pl-3 pr-3'}>
                Lust auf ein einzigartiges Kinoerlebnis?
              </div>
              <Row className="p-4">
                <Image
                  src={image1}
                  fluid
                  alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                  title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                />
              </Row>
              <div className={Kinostyles.divFlex + ' mt-2'}>
                <div className={Kinostyles.divCenterHalf}>
                  <Image
                    src={iconSieger}
                    width={80}
                    alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                    title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                  />
                  <div className={Kinostyles.TextContainerSmall}>
                    Österreichs beliebtestes
                    <br />
                    Kino 2023
                  </div>
                </div>
                <div className={Kinostyles.divCenterHalf}>
                  <Image
                    src={iconPrivat}
                    width={80}
                    alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                    title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                    className={Kinostyles.IconImage}
                  />
                  <div className={Kinostyles.TextContainerSmall}>
                    Privatssphäre im eigenen Auto
                  </div>
                </div>
              </div>
              <div className={Kinostyles.divFlex + ' mb-4'}>
                <div className={Kinostyles.divCenterHalf}>
                  <Image
                    src={iconVierbeiner}
                    width={80}
                    alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                    title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                    class
                  />
                  <div className={Kinostyles.TextContainerSmall}>
                    Nimm deinen Vier-beiner einfach mit
                  </div>
                </div>
                <div className={Kinostyles.divCenterHalf}>
                  <Image
                    src={iconSnacks}
                    width={80}
                    alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                    title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                    className={Kinostyles.IconImage}
                  />
                  <div className={Kinostyles.TextContainerSmall}>
                    Snacks direkt ins Auto geliefert
                  </div>
                </div>
              </div>
              <br />
              <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
                Du warst schon mal bei uns?
              </div>
              <div className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1'}>
                Schön dich wiederzusehen! Da du schon Autokinoprofi bist, schaue
                doch gleich weiter zu unserem Kinoprogramm.
              </div>
              <br />
              <div className={Kinostyles.CenterContent}>
                <button
                  className={Kinostyles.CTAButton}
                  onClick={e => scrollTo('kinoprogramm')}
                >
                  Zum Kinoprogramm
                </button>
              </div>
              <br />
              <br />
            </Col>
          </Row>
        </ResponsiveWrapper>
        <ResponsiveWrapper>
          <Row className={Kinostyles.Flex + ' d-none d-lg-block'}>
            <Col lg={12}>
              <Row>
                <Col xs={0} lg={5}>
                  <h1 className={Kinostyles.BanthinkHeadlineYellow}>
                    Kinoprogramm
                  </h1>
                  <div
                    className={
                      Kinostyles.TextContainer +
                      ' ' +
                      Kinostyles.BiggerTextFontSize +
                      ' pr-3'
                    }
                  >
                    Lust auf ein einzigartiges Kinoerlebnis?
                  </div>
                  <div className={Kinostyles.divFlex}>
                    <div className={Kinostyles.divCenterHalf}>
                      <Image
                        src={iconSieger}
                        width={80}
                        alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                        title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                      />
                      <div className={Kinostyles.TextContainerSmallNoMargin}>
                        Österreichs beliebtestes Kino 2023
                      </div>
                    </div>
                    <div className={Kinostyles.divCenterHalf}>
                      <Image
                        src={iconPrivat}
                        width={80}
                        alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                        title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                      />
                      <div className={Kinostyles.TextContainerSmallNoMargin}>
                        Privatssphäre im eigenen Auto
                      </div>
                    </div>
                  </div>
                  <div className={Kinostyles.divFlex}>
                    <div className={Kinostyles.divCenterHalf}>
                      <Image
                        src={iconVierbeiner}
                        width={80}
                        alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                        title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                        class
                      />
                      <div className={Kinostyles.TextContainerSmallNoMargin}>
                        Nimm deinen Vier-beiner einfach mit
                      </div>
                    </div>
                    <div className={Kinostyles.divCenterHalf}>
                      <Image
                        src={iconSnacks}
                        width={80}
                        alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                        title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                      />
                      <div className={Kinostyles.TextContainerSmallNoMargin}>
                        Snacks direkt ins Auto geliefert
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={0} lg={7}>
                  <Carousel xs={12} lg={12} interval={10000}>
                    <Carousel.Item>
                      <Image
                        src={image1Desktop}
                        fluid
                        alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                        title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                        className="p-4 mt-4"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        src={image2Desktop}
                        fluid
                        alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                        title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                        className="p-4 mt-4"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <Image
                        src={image3Desktop}
                        fluid
                        alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                        title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                        className="p-4 mt-4"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Col>
              </Row>
              <br />
              <br />
              <div className={Kinostyles.TextHeadlineBigCenter + ' mt-2'}>
                Du warst schon mal bei uns?
              </div>
              <div
                className={
                  Kinostyles.TextContainer +
                  ' ' +
                  Kinostyles.CenterContent +
                  ' pl-4 pr-4 pt-1'
                }
              >
                Schön dich wiederzusehen! Da du schon Autokinoprofi bist,
                <br /> schaue doch gleich weiter zu unserem Kinoprogramm.
              </div>
              <br />
              <div className={Kinostyles.CenterContent}>
                <button
                  className={Kinostyles.CTAButton}
                  onClick={e => scrollTo('kinoprogramm')}
                >
                  Zum Kinoprogramm
                </button>
              </div>
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </ResponsiveWrapper>
        <div className="d-lg-none">
          <ResponsiveWrapper>
            <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
              Du warst noch nie bei uns?
            </div>
            <div className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1'}>
              Wie aufregend! Aber keine Sorge, Autokino funktioniert einfacher
              als du denkst:
            </div>
            <br />
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1'}>
              <Col xs={2}>
                <img
                  src={imageZeitleiste1}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col xs={10}>
                <div className={Kinostyles.TextPurple}>
                  Wähle deine Begleitung 👥
                </div>
                <div className={Kinostyles.TextContainer}>
                  Egal ob Date, Familienausflug, Girls Night oder ein Kinoabend
                  mit deinem Hund, ein Besuch im Autokino eignet sich für jeden
                  Anlass!
                </div>
              </Col>
            </Row>
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1 mt-4'}>
              <Col xs={2}>
                <img
                  src={imageZeitleiste2}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col xs={10}>
                <div className={Kinostyles.TextPurple}>
                  Wähle dein Fahrzeug 🚗 🚲 🏍️🚶‍♀️
                </div>
                <div className={Kinostyles.TextContainer}>
                  Ins Autokino kannst du sowohl klassisch mit dem Auto als auch
                  alternativ mit dem Fahrrad, Motorrad oder zu Fuß kommen!
                </div>
              </Col>
            </Row>
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1 mt-4'}>
              <Col xs={2}>
                <img
                  src={imageZeitleiste3}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col xs={10}>
                <div className={Kinostyles.TextPurple}>
                  Wähle deinen Film und sichere dir deine Tickets 🎥 🎟️
                </div>
                <div className={Kinostyles.TextContainer}>
                  Als letzten Schritt wählst du deinen perfekten Film aus und
                  sicherst dir die passenden Tickets.
                </div>
              </Col>
            </Row>
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1 mt-4'}>
              <Col xs={2}>
                <img
                  src={imageZeitleiste4}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col xs={10}>
                <div className={Kinostyles.TextPurple}>
                  Zurücklehnen & Entspannen 🧘🏽
                </div>
                <div className={Kinostyles.TextContainer}>
                  Voilà, du hast dein ideales Kino-Setup zusammengestellt. Den
                  Rest übernehmen jetzt wir – lasse dich einfach von uns
                  verwöhnen!
                </div>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12} xl={12}>
                <div className={Kinostyles.PSBox}>
                  <font className={Kinostyles.TextPurple}>PS: </font>
                  Bei uns gibt es auch ein <b>riesiges Snackangebot.</b> Auf
                  dich warten sowohl <b>klassische Kinosnacks</b> als auch{' '}
                  <b>Cocktails vom Profi, leckere Hotdogs (auch vegan)!</b> Zum{' '}
                  <b>Selbstabholen</b> oder auch jederzeit (ohne Aufpreis) zu
                  dir <b>ins Auto geliefert!</b>
                </div>
              </Col>
            </Row>
            <br />
          </ResponsiveWrapper>
        </div>

        <div className={Kinostyles.GrayContainer + ' d-none d-lg-block'}>
          <ResponsiveWrapper>
            <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
              Du warst noch nie bei uns?
            </div>
            <div className={Kinostyles.TextContainer + ' pr-4 pt-1'}>
              Wie aufregend! Aber keine Sorge, Autokino funktioniert einfacher
              als du denkst:
            </div>
            <br />
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1'}>
              <Col lg={1} />
              <Col lg={1}>
                <img
                  src={imageZeitleiste1}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col lg={7}>
                <div className={Kinostyles.TextPurple}>
                  Wähle deine Begleitung 👥
                </div>
                <div className={Kinostyles.TextContainer}>
                  Egal ob Date, Familienausflug, Girls Night oder ein Kinoabend
                  mit deinem Hund, ein Besuch im Autokino eignet sich für jeden
                  Anlass!
                </div>
              </Col>
              <Col lg={2} />
            </Row>
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1 mt-4'}>
              <Col lg={1} />
              <Col lg={1}>
                <img
                  src={imageZeitleiste2}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col lg={7}>
                <div className={Kinostyles.TextPurple}>
                  Wähle dein Fahrzeug 🚗 🚲 🏍️🚶‍♀️
                </div>
                <div className={Kinostyles.TextContainer}>
                  Ins Autokino kannst du sowohl klassisch mit dem Auto als auch
                  alternativ mit dem Fahrrad, Motorrad oder zu Fuß kommen!
                </div>
              </Col>
              <Col lg={2} />
            </Row>
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1 mt-4'}>
              <Col lg={1} />
              <Col lg={1}>
                <img
                  src={imageZeitleiste3}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col lg={7}>
                <div className={Kinostyles.TextPurple}>
                  Wähle deinen Film und sichere dir deine Tickets 🎥 🎟️
                </div>
                <div className={Kinostyles.TextContainer}>
                  Als letzten Schritt wählst du deinen perfekten Film aus und
                  sicherst dir die passenden Tickets.
                </div>
              </Col>
              <Col lg={2} />
            </Row>
            <Row className={Kinostyles.TextContainer + ' pl-4 pr-4 pt-1 mt-4'}>
              <Col lg={1} />
              <Col lg={1}>
                <img
                  src={imageZeitleiste4}
                  alt="Auto"
                  style={{ width: '30px', marginLeft: '2.5vw' }}
                />
              </Col>
              <Col lg={7}>
                <div className={Kinostyles.TextPurple}>
                  Zurücklehnen & Entspannen 🧘🏽
                </div>
                <div className={Kinostyles.TextContainer}>
                  Voilà, du hast dein ideales Kino-Setup zusammengestellt. Den
                  Rest übernehmen jetzt wir – lasse dich einfach von uns
                  verwöhnen!
                </div>
              </Col>
              <Col lg={2} />
            </Row>
            <br />
            <Row>
              <Col lg={1} />
              <Col lg={9}>
                <div className={Kinostyles.PSBox}>
                  <font className={Kinostyles.PSBoxPSText}>PS: </font>
                  Bei uns gibt es auch ein <b>riesiges Snackangebot.</b> Auf
                  dich warten sowohl <b>klassische Kinosnacks 🍿</b> als auch{' '}
                  <b>
                    Cocktails vom Profi 🍹, leckere Hotdogs 🌭 (auch vegan)!
                  </b>{' '}
                  Zum <b>Selbstabholen</b> oder auch jederzeit (ohne Aufpreis)
                  zu dir <b>ins Auto geliefert!</b>
                </div>
              </Col>
            </Row>
            <br />
          </ResponsiveWrapper>
        </div>
        <br />
        <ResponsiveWrapper>
          <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
            Was unsere BesucherInnen sagen:
          </div>
          <div className={Kinostyles.TextPurpleSmall + ' mt-2'}>
            Weil wir guten Service lieben ❤️
          </div>
          <br />
          <div className="d-lg-none ml-4">
            <Carousel
              xs={12}
              lg={12}
              interval={10000}
              prevIcon={getPrefIcon()}
              nextIcon={getNextIcon()}
            >
              <Carousel.Item>
                <div className={Kinostyles.RezBox}>
                  <div className={Kinostyles.RezBoxName}>Katharina Stark</div>
                  <Row>
                    <Col xs={5}>
                      {' '}
                      <Image
                        src={imageSterne}
                        width={110}
                        alt="5 sterne bewertung"
                        title="tolle bewertung mit 5 Sternen eines Besuchers"
                      />
                    </Col>
                    <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                      August 2023
                    </Col>
                  </Row>
                  <div className={Kinostyles.RezBoxText}>
                    Wir fahren mehrmals in der Saison ins Autokino, Gute Musik
                    (vor dem Film), angenehme Atmosphäre. Gastro voll in
                    Ordnung, so gute Popcorn bekommt man selten, Cocktails 1A.
                    Einfach super, da Lautstärke selbst angepasst werden kann
                    und die Hunde mässen nicht alleine daheim bleiben. Wenn es
                    frisch wird, einfach Decke mitnehmen, dann ist es schön
                    kuschelig
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={Kinostyles.RezBox}>
                  <div className={Kinostyles.RezBoxName}>Daniel G.</div>
                  <Row>
                    <Col xs={5}>
                      {' '}
                      <Image
                        src={imageSterne}
                        width={110}
                        alt="5 sterne bewertung"
                        title="tolle bewertung mit 5 Sternen eines Besuchers"
                      />
                    </Col>
                    <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                      September 2023
                    </Col>
                  </Row>
                  <div className={Kinostyles.RezBoxText}>
                    Ich hatte gemischte Gefühle vor unserem ersten Besuch,
                    einfach nur weil es neu war und ich einige Erwartungen
                    hatte. Kurz gesagt: Es war ein tolles Erlebnis - alles war
                    hoch professionell, alle waren sehr freundlich und die
                    Stimmung war toll. Die Snack Lieferungen ins Auto waren nur
                    eines meiner Highlights. Wir kommen auf jeden Fall wieder -
                    ins normale Kino möchte man, nach einem besuch im Autokino
                    Wien, gar nicht mehr gehen.
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={Kinostyles.RezBox}>
                  <div className={Kinostyles.RezBoxName}>Markus Habek</div>
                  <Row>
                    <Col xs={5}>
                      {' '}
                      <Image
                        src={imageSterne}
                        width={110}
                        alt="5 sterne bewertung"
                        title="tolle bewertung mit 5 Sternen eines Besuchers"
                      />
                    </Col>
                    <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                      August 2020
                    </Col>
                  </Row>
                  <div className={Kinostyles.RezBoxText}>
                    Endlich wieder Autokino nach der langen Zeit. Sehr
                    nostalgisches Feeling 🥰 Film (Tenet) war spitze. Das Wetter
                    hat nicht so mitgespielt. Dafür konnten die Betreiber aber
                    nix. Die Cocktails sind super gemischt und Preiswert. Die
                    Lounge sehr lässig und der Service absolut Top!!!! Absolut
                    empfehlenswert! 🙌🙌
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={Kinostyles.RezBox}>
                  <div className={Kinostyles.RezBoxName}>Verena Viderman</div>
                  <Row>
                    <Col xs={5}>
                      {' '}
                      <Image
                        src={imageSterne}
                        width={110}
                        alt="5 sterne bewertung"
                        title="tolle bewertung mit 5 Sternen eines Besuchers"
                      />
                    </Col>
                    <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                      September 2023
                    </Col>
                  </Row>
                  <div className={Kinostyles.RezBoxText}>
                    Mega Erlebnis!!!! 🤩Muss man erlebt haben. Wir freuen uns
                    schon auf das Kinoprogramm 2024. Die Crew ist bestens
                    organisiert, sehr freundlich und zuvorkommend. Das Areal
                    bietet ausreichend Platz und man hat sehr gute Sicht auf die
                    Leinwand. Wirklich empfehlenswert 👍👍🤩🤩
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={Kinostyles.RezBox}>
                  <div className={Kinostyles.RezBoxName}>Klaus PETER</div>
                  <Row>
                    <Col xs={5}>
                      {' '}
                      <Image
                        src={imageSterne}
                        width={110}
                        alt="5 sterne bewertung"
                        title="tolle bewertung mit 5 Sternen eines Besuchers"
                      />
                    </Col>
                    <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                      Juni 2022
                    </Col>
                  </Row>
                  <div className={Kinostyles.RezBoxText}>
                    Ich war letzte Woche das erst mal in Autokino, also das ist
                    ja genial bei euch. Ich werd jetzt sicher öfter
                    vorbeikommen. Die Einteilung bei euch läuft so professionell
                    ab, hut ab :) Ich hatte mir Ambulance angesehen! Auch das
                    Essen zum Auto liefern klappt super. Schön das es euch gibt!
                    <br />
                    <br /> Liebe grüße
                    <br /> Klaus
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={Kinostyles.RezBox}>
                  <div className={Kinostyles.RezBoxName}>Stefan Hartl</div>
                  <Row>
                    <Col xs={5}>
                      {' '}
                      <Image
                        src={imageSterne}
                        width={110}
                        alt="5 sterne bewertung"
                        title="tolle bewertung mit 5 Sternen eines Besuchers"
                      />
                    </Col>
                    <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                      August 2023
                    </Col>
                  </Row>
                  <div className={Kinostyles.RezBoxText}>
                    Nach Jahren wieder einmal im Autokino. Nicht zu vergleichen
                    mit FRÜHER. Tolle Organisation. Super nettes Personal.
                    Tolles Essen und Trinken. Der Lieferservice ein Traum. Aber
                    das wichtigste... die Toiletten sind komplett renoviert.
                    Super Abend mit meinem Jr. verbracht.
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className={'d-none d-lg-block'}>
            <Carousel
              xs={12}
              lg={12}
              interval={10000}
              prevIcon={getPrefIcon()}
              nextIcon={getNextIcon()}
            >
              <Carousel.Item>
                <Row>
                  <Col lg={2} />
                  <Col lg={4}>
                    <div className={Kinostyles.RezBox}>
                      <div className={Kinostyles.RezBoxName}>
                        Katharina Stark
                      </div>
                      <Row>
                        <Col xs={5}>
                          {' '}
                          <Image
                            src={imageSterne}
                            width={110}
                            alt="5 sterne bewertung"
                            title="tolle bewertung mit 5 Sternen eines Besuchers"
                          />
                        </Col>
                        <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                          August 2023
                        </Col>
                      </Row>
                      <div className={Kinostyles.RezBoxText}>
                        Wir fahren mehrmals in der Saison ins Autokino, Gute
                        Musik (vor dem Film), angenehme Atmosphäre. Gastro voll
                        in Ordnung, so gute Popcorn bekommt man selten,
                        Cocktails 1A. Einfach super, da Lautstärke selbst
                        angepasst werden kann und die Hunde mässen nicht alleine
                        daheim bleiben. Wenn es frisch wird, einfach Decke
                        mitnehmen, dann ist es schön kuschelig
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={Kinostyles.RezBox}>
                      <div className={Kinostyles.RezBoxName}>Daniel G.</div>
                      <Row>
                        <Col xs={5}>
                          {' '}
                          <Image
                            src={imageSterne}
                            width={110}
                            alt="5 sterne bewertung"
                            title="tolle bewertung mit 5 Sternen eines Besuchers"
                          />
                        </Col>
                        <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                          September 2023
                        </Col>
                      </Row>
                      <div className={Kinostyles.RezBoxText}>
                        Ich hatte gemischte Gefühle vor unserem ersten Besuch,
                        einfach nur weil es neu war und ich einige Erwartungen
                        hatte. Kurz gesagt: Es war ein tolles Erlebnis - alles
                        war hoch professionell, alle waren sehr freundlich und
                        die Stimmung war toll. Die Snack Lieferungen ins Auto
                        waren nur eines meiner Highlights. Wir kommen auf jeden
                        Fall wieder - ins normale Kino möchte man, nach einem
                        besuch im Autokino Wien, gar nicht mehr gehen.
                      </div>
                    </div>
                  </Col>
                  <Col lg={2} />
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row>
                  <Col lg={2} />
                  <Col lg={4}>
                    <div className={Kinostyles.RezBox}>
                      <div className={Kinostyles.RezBoxName}>Markus Habek</div>
                      <Row>
                        <Col xs={5}>
                          {' '}
                          <Image
                            src={imageSterne}
                            width={110}
                            alt="5 sterne bewertung"
                            title="tolle bewertung mit 5 Sternen eines Besuchers"
                          />
                        </Col>
                        <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                          August 2020
                        </Col>
                      </Row>
                      <div className={Kinostyles.RezBoxText}>
                        Endlich wieder Autokino nach der langen Zeit. Sehr
                        nostalgisches Feeling 🥰 Film (Tenet) war spitze. Das
                        Wetter hat nicht so mitgespielt. Dafür konnten die
                        Betreiber aber nix. Die Cocktails sind super gemischt
                        und Preiswert. Die Lounge sehr lässig und der Service
                        absolut Top!!!! Absolut empfehlenswert! 🙌🙌
                      </div>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className={Kinostyles.RezBox}>
                      <div className={Kinostyles.RezBoxName}>
                        Verena Viderman
                      </div>
                      <Row>
                        <Col xs={5}>
                          {' '}
                          <Image
                            src={imageSterne}
                            width={110}
                            alt="5 sterne bewertung"
                            title="tolle bewertung mit 5 Sternen eines Besuchers"
                          />
                        </Col>
                        <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                          September 2023
                        </Col>
                      </Row>
                      <div className={Kinostyles.RezBoxText}>
                        Mega Erlebnis!!!! 🤩Muss man erlebt haben. Wir freuen
                        uns schon auf das Kinoprogramm 2024. Die Crew ist
                        bestens organisiert, sehr freundlich und zuvorkommend.
                        Das Areal bietet ausreichend Platz und man hat sehr gute
                        Sicht auf die Leinwand. Wirklich empfehlenswert 👍👍🤩🤩
                      </div>
                    </div>
                  </Col>
                  <Col lg={2} />
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row>
                  <Col lg={2} />
                  <Col lg={4}>
                    <div className={Kinostyles.RezBox}>
                      <div className={Kinostyles.RezBoxName}>Klaus PETER</div>
                      <Row>
                        <Col xs={5}>
                          {' '}
                          <Image
                            src={imageSterne}
                            width={110}
                            alt="5 sterne bewertung"
                            title="tolle bewertung mit 5 Sternen eines Besuchers"
                          />
                        </Col>
                        <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                          Juni 2022
                        </Col>
                      </Row>
                      <div className={Kinostyles.RezBoxText}>
                        Ich war letzte Woche das erst mal in Autokino, also das
                        ist ja genial bei euch. Ich werd jetzt sicher öfter
                        vorbeikommen. Die Einteilung bei euch läuft so
                        professionell ab, hut ab :) Ich hatte mir Ambulance
                        angesehen! Auch das Essen zum Auto liefern klappt super.
                        Schön das es euch gibt!
                        <br />
                        <br /> Liebe grüße
                        <br /> Klaus
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className={Kinostyles.RezBox}>
                      <div className={Kinostyles.RezBoxName}>Stefan Hartl</div>
                      <Row>
                        <Col xs={5}>
                          {' '}
                          <Image
                            src={imageSterne}
                            width={110}
                            alt="5 sterne bewertung"
                            title="tolle bewertung mit 5 Sternen eines Besuchers"
                          />
                        </Col>
                        <Col xs={7} className={Kinostyles.RezBoxGraySmall}>
                          August 2023
                        </Col>
                      </Row>
                      <div className={Kinostyles.RezBoxText}>
                        Nach Jahren wieder einmal im Autokino. Nicht zu
                        vergleichen mit FRÜHER. Tolle Organisation. Super nettes
                        Personal. Tolles Essen und Trinken. Der Lieferservice
                        ein Traum. Aber das wichtigste... die Toiletten sind
                        komplett renoviert. Super Abend mit meinem Jr.
                        verbracht.
                      </div>
                    </div>
                  </Col>
                  <Col lg={2} />
                </Row>
              </Carousel.Item>
            </Carousel>
          </div>
          <br />
          <Row id={'kinoprogramm'}>
            <Col lg={3} />
            <Col lg={6}>
              <Image
                src={imageTipp}
                fluid
                alt="junges_paerchen_wartet_auf_kinoprogramm_start_wien"
                title="glueckliche_kinobesucher_warten_auf_kinoprogramm"
                className="p-2 mt-4"
              />
            </Col>
            <Col lg={3} />
          </Row>
        </ResponsiveWrapper>
        <br />
        <br />
      </div>
      <div>
        <ShowList
          shows={props.shows}
          saveMovieReminderSubscription={props.saveMovieReminderSubscription}
        />
      </div>
      <div>
        <br />
        <div className="d-lg-none">
          <Row className={Kinostyles.Flex}>
            <Col xs={12} lg={12}>
              <div className={Kinostyles.TextHeadlineBig + ' mt-4'}>
                Keinen passenden Film gefunden?
              </div>
              <div className={Kinostyles.TextContainer + ' mt-1 ml-4'}>
                Keine Sorge, wir erweitern unser Programm regelmäßig. Bleib
                stets informiert, indem du unseren kostenlosen wöchentlichen
                Kinoprogramm Newsletter abonnierst, damit du keinen Film
                verpasst.
                <br />
                <br />
              </div>
              <div className={Kinostyles.CenterContent}>
                <FilmprogrammSignup />
              </div>
              <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
                Du möchtest das Kinoprogramm mitgestalten?
              </div>
              <div className={Kinostyles.TextContainer + ' mt-1 ml-4'}>
                Merke dich für Filme, Genres und Sprachen vor & lasse uns auf
                diese Weise wissen, was wir spielen sollen! Wir informieren dich
                gratis, sobald wir deinen Wunschfilm/-Genre/-Sprache spielen.
                #EuerKino
                <br />
                <br />
              </div>
              <div className={Kinostyles.CenterContent}>
                <button className={Kinostyles.CTAButtonNew}>
                  <a
                    href={ROUTES.UPCOMING_MOVIES}
                    className={Kinostyles.CTAButtonLinkNew}
                  >
                    Jetzt vormerken
                  </a>
                </button>
              </div>
              <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
                Du möchtest gemeinsame Zeit verschenken?
              </div>
              <div className={Kinostyles.TextContainer + ' mt-1 ml-4'}>
                Überrasche deine Liebsten mit einem Autokino Gutschein und somit
                einer unvergesslichen Zeit hier im Autokino. Weil gemeinsame
                Zeit wertvoll ist.
                <br />
                <br />
              </div>
              <div className={Kinostyles.CenterContent}>
                <button className={Kinostyles.CTAButtonNew}>
                  <a
                    href={ROUTES.GIFTCARD}
                    className={Kinostyles.CTAButtonLinkNew}
                  >
                    Gutscheine online kaufen
                  </a>
                </button>
              </div>
              <br />
              <br />
            </Col>
          </Row>
        </div>
        <div className={'d-none d-lg-block'}>
          <ResponsiveWrapper>
            <Row className={Kinostyles.Flex}>
              <Col lg={2} />
              <Col lg={8}>
                <div className={Kinostyles.TextHeadlineBig + ' mt-4'}>
                  Keinen passenden Film gefunden?
                </div>
                <div className={Kinostyles.TextContainer + ' mt-1'}>
                  Keine Sorge, wir erweitern unser Programm regelmäßig. Bleib
                  stets informiert, indem du unseren kostenlosen wöchentlichen
                  Kinoprogramm Newsletter abonnierst, damit du keinen Film
                  verpasst.
                  <br />
                  <br />
                </div>
                <div className={Kinostyles.TextContainer}>
                  <FilmprogrammSignup />
                  <br />
                  <br />
                </div>
                <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
                  Du möchtest das Kinoprogramm mitgestalten?
                </div>
                <div className={Kinostyles.TextContainer + ' mt-1'}>
                  Merke dich für Filme, Genres und Sprachen vor & lasse uns auf
                  diese Weise wissen, was wir spielen sollen! Wir informieren
                  dich gratis, sobald wir deinen Wunschfilm/-Genre/-Sprache
                  spielen. #EuerKino
                  <br />
                  <br />
                </div>
                <div className={Kinostyles.TextContainer}>
                  <button className={Kinostyles.CTAButtonNew}>
                    <a
                      href={ROUTES.UPCOMING_MOVIES}
                      className={Kinostyles.CTAButtonLinkNew}
                    >
                      Jetzt vormerken
                    </a>
                  </button>
                  <br />
                  <br />
                  <br />
                </div>
                <div className={Kinostyles.TextHeadlineBig + ' mt-2'}>
                  Du möchtest gemeinsame Zeit verschenken?
                </div>
                <div className={Kinostyles.TextContainer + ' mt-1'}>
                  Überrasche deine Liebsten mit einem Autokino Gutschein und
                  somit einer unvergesslichen Zeit hier im Autokino. Weil
                  gemeinsame Zeit wertvoll ist.
                  <br />
                  <br />
                </div>
                <div className={Kinostyles.TextContainer}>
                  <button className={Kinostyles.CTAButtonNew}>
                    <a
                      href={ROUTES.GIFTCARD}
                      className={Kinostyles.CTAButtonLinkNew}
                    >
                      Gutscheine online kaufen
                    </a>
                  </button>
                </div>
                <br />
                <br />
              </Col>
              <Col lg={2} />
            </Row>
          </ResponsiveWrapper>
        </div>
      </div>
    </div>
  );
};

LandingPage.propTypes = {
  fetchShows: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    shows: state.shows.shows,
  };
};

export default connect(
  mapStateToProps,
  { fetchShows, saveMovieReminderSubscription }
)(LandingPage);
