import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import * as APP_CONST from '../../constants/appConsts';
import {
  Title,
  Summary,
  Items,
  DiscountCode,
} from '../../components/Cart/Cart';
import ResponsiveWrapper from '../../components/UI/ResponsiveWrapper/ResponsiveWrapper';
import * as actions from '../../store/actions';
import { CART as cartTitle } from '../../constants/titles';
import Button from '../../components/UI/Button/Button';
import { Link } from 'react-router-dom';
import { CHANUKKA } from '../../constants/routes';
import itemsStyles from '../../components/Cart/Items/Items.module.css';

/**
 * TODO
 * Move the calculations
 * to server
 */

class CartPage extends Component {
  state = {
    cart: this.props.cart,
    oldCart: this.props.cart,
    totalPrice: CartPage.calcTotalPrice(this.props.cart),
    loading: false,
    error: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // Everytime the component receives
    // props this function compares the mirror
    // of the cart and the current cart.
    // If the cart was updated, the function
    // calculates the totalPrice and store the new mirror
    return {
      cart: nextProps.cart,
      oldCart: nextProps.cart,
      totalPrice: CartPage.calcTotalPrice(nextProps.cart),
    };
  }

  renderVehicleButton = workdata => {
    console.log('in renderVehicleButton');
    var isChanukka = false;
    const data = { ...this.state.cart };
    let cartArray = Object.values(data);
    console.log('cart array lenght = ' + cartArray.length);
    cartArray.forEach((value, index) => {
      try {
        if (
          value !== null &&
          value.show !== null &&
          value.show.showId === 233
        ) {
          isChanukka = true;
        }
      } catch {}
    });
    if (isChanukka) {
      return (
        <Button
          theme="big"
          className={['btn', 'btn-first', itemsStyles.ButtonCTA].join(' ')}
          disabled={false}
        >
          <Link
            to={`${CHANUKKA}`}
            disabled={false}
            title="Weiteres Auto hinzufügen"
            className="linkButtonStyle"
          >
            Weiteres Auto hinzufügen
          </Link>
        </Button>
      );
    } else {
      return '';
    }
  };

  removeFromCart = id => {
    const data = { ...this.state.cart };
    let cartArray = Object.values(data);
    console.log('cart array lenght = ' + cartArray.length + 'id = ' + id);
    cartArray.forEach((value, index) => {
      console.log('item with index = ' + index + 'id = ' + value.key);
    });
    var item2remove = cartArray.find(x => x.key === id);

    if (item2remove != null) {
      console.log('item to remove exists');
      let index = cartArray.indexOf(item2remove);
      if (index !== -1) {
        console.log('!!!index of item = ' + index);
        cartArray.splice(index, 1);

        if (
          item2remove.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
          item2remove.productType === APP_CONST.PRODUCT_TYPE_EVENT
        ) {
          const foundItems = cartArray.filter(
            x =>
              (x.productType === APP_CONST.PRODUCT_TYPE_SHOW_PRODUCT ||
                x.productType === APP_CONST.PRODUCT_TYPE_EVENT_PRODUCT) &&
              x.key.startsWith(item2remove.key + '|')
          );
          if (foundItems != null && foundItems.length > 0) {
            foundItems.forEach(p => {
              index = cartArray.indexOf(p);
              if (index !== -1) {
                cartArray.splice(index, 1);
              }
            });
          }
        } else if (
          item2remove.productType === APP_CONST.PRODUCT_TYPE_SHOW_PRODUCT ||
          item2remove.productType === APP_CONST.PRODUCT_TYPE_EVENT_PRODUCT
        ) {
          let foundItem = cartArray.find(
            x =>
              (x.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
                x.productType === APP_CONST.PRODUCT_TYPE_EVENT) &&
              x.key === item2remove.show.showKey
          );
          foundItem.totalPrice = (
            foundItem.totalPrice - item2remove.totalPrice
          ).toFixed(2);
        }
      }
    }
    console.log('beffore cibncat');
    let dataNew = {};
    cartArray.forEach(doc => {
      console.log('cart item id = ' + doc.key);
      dataNew[doc.key] = doc;
    });

    let total = CartPage.calcTotalPrice(dataNew);
    this.setState({
      cart: dataNew,
      totalPrice: total,
    });

    this.props.onRemoveFromCart(dataNew, total);
  };

  applyCode = (code, e) => {
    if (code === '' || code === null) {
      e.preventDefault();
      return;
    }
    console.log('applyDiscount code = "' + code + '"');
    const data = { ...this.state.cart };

    const cartArray = Object.values(data);
    const shows = cartArray.filter(
      x =>
        x.productType === APP_CONST.PRODUCT_TYPE_SHOW ||
        x.productType === APP_CONST.PRODUCT_TYPE_EVENT
    );
    let itemShows = [];
    if (shows !== null && shows.length > 0) {
      shows.forEach(p => {
        console.log('draw show with Id= ' + p.id);
        const products = cartArray.filter(
          x =>
            (x.productType === APP_CONST.PRODUCT_TYPE_SHOW_PRODUCT ||
              x.productType === APP_CONST.PRODUCT_TYPE_EVENT_PRODUCT) &&
            x.key.startsWith(p.key + '|')
        );

        if (products !== null && products.length > 0) {
          console.log('found subitems= ' + products.length);
          let items2Export = [];
          products.forEach(item => {
            console.log(
              'add subtitem to collection = ' +
                item.id +
                ', purchaseId' +
                item.product.purchaseProductId
            );

            items2Export.push({
              purchaseProductId: item.product.purchaseProductId,
              productName: item.product.productName,
              description: item.product.description,
              price: item.product.price,
              taxrate: item.product.taxrate,
              mandatory: item.product.mandatory,
              productOrderType: item.product.productOrderType,
              count: item.amount,
              sortPosition: item.sortPostion,
              voucherCode: item.voucherCode,
              voucherCategory: item.voucherCategory,
              discountPurchaseProductId: item.product.discountPurchaseProductId,
              productCateogry: item.product.productCateogry
            });
          });

          const show = {
            showId: p.id,
            products: items2Export,
          };
          itemShows.push(show);
        }
      });
    }
    let gutscheine2Export = [];
    const gutscheine = cartArray.filter(
      x =>
        x.productType === APP_CONST.PRODUCT_TYPE_VOUCHER ||
        x.productType === APP_CONST.PRODUCT_TYPE_VOUCHER_ITEM
    );
    if (gutscheine != null && gutscheine.length > 0) {
      gutscheine.forEach(p => {
        gutscheine2Export.push({
          purchaseProductId:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER
              ? p.product.purchaseProductId
              : p.purchaseProductId,
          productName:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER
              ? p.product.productName
              : p.productName,
          description:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER
              ? p.product.description
              : p.description,
          price:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER ? p.product.price : p.price,
          taxrate:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER ? p.product.taxrate : p.taxrate,
          mandatory:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER
              ? p.product.mandatory
              : p.mandatory,
          productOrderType:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER
              ? p.product.productOrderType
              : p.productOrderType,
          productType:
            p == APP_CONST.PRODUCT_TYPE_VOUCHER
              ? p.product.productType
              : p.productType,
          count: p.amount,
        });
      });

      const show = {
        showId: 0,
        products: gutscheine2Export,
      };
      itemShows.push(show);
    }

    if (itemShows !== null && itemShows.length > 0 && code !== '') {
      const order = {
        voucherCode: code,
        items: itemShows,
      };

      this.props.onApplyDiscountCode(order);
    }
  };

  validateCheckout = e => {
    const data = { ...this.state.cart };
    const totalPrice = this.state.totalPrice;
    let total = CartPage.calcTotalPrice(data);
    let allAmountZero = CartPage.allAmountZero(data);

    // console.log('total = ' + total + ',totalPrice = ' + totalPrice + ', allAmountZero = ' + allAmountZero);

    if (total == 0 && allAmountZero) {
      this.props.onAddNotification('Der Warenkorb darf nicht leer sein.');
      e.preventDefault();
      return;
    }

    if (total !== totalPrice) {
      this.props.onAddNotification(
        'Summe der Einzelpositionen stimmt nicht mit der Gesamtsumme überein. Bitte überprüfen Sie die eingegebenen Mengen!'
      );
      e.preventDefault();
    }
  };

  counterHandler = (e, id, type) => {
    const data = { ...this.state.cart };
    const oldItem = data[id];
    const oldCounter = oldItem.amount;
    let updatedCounter = oldCounter;
    let updatedTotalPrice = oldItem.totalPrice;

    if (type === 'inc') {
      updatedCounter += 1;
      updatedTotalPrice += oldItem.product.price;
    }

    if (type === 'dec') {
      if (updatedCounter > 1) {
        updatedCounter -= 1;
        updatedTotalPrice -= oldItem.product.price;
      } else if (updatedCounter === 1 && !oldItem.product.mandatory) {
        updatedCounter -= 1;
        updatedTotalPrice -= oldItem.product.price;
      }
    }

    if (type === 'change') {
      const val = +e.target.value;
      if (
        (val >= 0 && !oldItem.product.mandatory) ||
        (val >= 1 && oldItem.product.mandatory)
      ) {
        updatedCounter = Math.abs(val);
        updatedTotalPrice = updatedCounter * oldItem.product.price;
      }
      if (val > 600) {
        updatedCounter = 600;
        updatedTotalPrice = updatedCounter * oldItem.product.price;
      }
    }

    const updatedItem = {
      ...oldItem,
      amount: updatedCounter,
      totalPrice: +updatedTotalPrice.toFixed(2),
    };

    console.log(
      'updatedPrice = ' +
        updatedTotalPrice +
        'updatedCounter = ' +
        updatedCounter
    );

    data[id] = updatedItem;

    // update total fuer show row
    if (
      updatedItem.productType === APP_CONST.PRODUCT_TYPE_SHOW_PRODUCT ||
      updatedItem.productType === APP_CONST.PRODUCT_TYPE_EVENT_PRODUCT
    ) {
      const parentItem = data[updatedItem.show.showKey];
      if (parentItem != null) {
        let oldPrice =
          Math.abs(parentItem.totalPrice) +
          (updatedTotalPrice - oldItem.totalPrice);
        console.log('oldPrice ' + oldPrice);
        const updatedParentItem = {
          ...parentItem,
          totalPrice: +oldPrice.toFixed(2),
        };
        data[updatedItem.show.showKey] = updatedParentItem;
      }
    }

    let total = CartPage.calcTotalPrice(data);
    this.setState({
      cart: data,
      totalPrice: total,
    });

    this.props.onUpdateCart(data, total);
  };

  static calcTotalPrice = data => {
    // data is object. so I need to convert
    // it to array

    let sum = 0;

    Object.values(data).forEach(p => {
      if (
        p.productType !== APP_CONST.PRODUCT_TYPE_SHOW &&
        p.productType !== APP_CONST.PRODUCT_TYPE_EVENT
      ) {
        if (p.totalPrice) {
          console.log(
            'totalPrice = ' + p.totalPrice + ' showDataId: ' + p.showId
          );
          sum += p.totalPrice;
        } else if (p.product) {
          sum += p.amount * p.product.price;
        } else {
          console.log('productType = ' + p.productType + 'price = ' + p.price);
          sum += p.count * p.price;
        }
      }
    });

    console.log('total price = ' + sum);
    return sum.toFixed(2);
  };

  static allAmountZero = data => {
    // data is object. so I need to convert
    // it to array

    let alleZero = true;

    Object.values(data).forEach(p => {
      if (
        p.productType !== APP_CONST.PRODUCT_TYPE_SHOW &&
        p.productType !== APP_CONST.PRODUCT_TYPE_EVENT
      ) {
        if (p.totalPrice) {
          if (p.totalPrice != 0) {
            alleZero = false;
          }
        } else if (p.product) {
          if (p.amount != 0) {
            alleZero = false;
          }
        } else {
          if (p.count != 0) {
            alleZero = false;
          }
        }
      }
    });

    return alleZero;
  };

  render() {
    return (
      <ResponsiveWrapper loading={this.props.isLoading}>
        <Helmet defer={false}>
          <title>{cartTitle}</title>
        </Helmet>
        <Title>Warenkorb</Title>
        <Items
          data={this.state.cart}
          loading={this.props.isLoading}
          onDeleteItem={id => this.removeFromCart(id)}
          isRemovingFromCart={this.props.isRemovingFromCart}
          incCounterClicked={(e, id) => this.counterHandler(e, id, 'inc')}
          decCounterClicked={(e, id) => this.counterHandler(e, id, 'dec')}
          onCounterChange={(e, id) => this.counterHandler(e, id, 'change')}
        />
        {this.renderVehicleButton(this.state.cart)}
        <div className={'mb-3'}>
          <DiscountCode
            applyCode={code => this.applyCode(code)}
            disableGutschein={true}
          />
          <Summary
            totalPrice={this.state.totalPrice}
            checkoutClicked={this.validateCheckout}
            disableCheckout={!Object.keys(this.state.cart).length}
          />
        </div>
      </ResponsiveWrapper>
    );
  }
}

CartPage.propTypes = {
  cart: PropTypes.object,
  isLoading: PropTypes.bool,
  onRemoveFromCart: PropTypes.func,
  onUpdateCart: PropTypes.func,
  onApplyDiscountCode: PropTypes.func,
  formIsValid: PropTypes.bool,
  doCheckout: PropTypes.func,
  onAddNotification: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    cart: state.cart.cart,
    isLoading: state.cart.loading,
    isRemovingFromCart: state.cart.isRemovingFromCart,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRemoveFromCart: (data, total) =>
      dispatch(actions.removeFromCart(data, total)),
    onUpdateCart: (data, total) => dispatch(actions.updateCart(data, total)),
    onApplyDiscountCode: order => dispatch(actions.applyDiscountCode(order)),
    onAddNotification: message => dispatch(actions.addNotification(message)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPage);
